export enum GTMEventName {
  "회원가입" = "signin",
  "후기 클릭" = "후기 클릭",
  "click_kakao-login(match)" = "click_kakao-login(match)",
  "로그인/회원가입 클릭" = "로그인/회원가입 클릭",
}

// 서로 다른 층위가 결합될 때는 언더바 사용. 같은 층위 내에서는 언더바도 대쉬로 바꾼다.
// (parlor_detail을 다른 층위와 엮어서 쓸 때는 parlor-detail)
export type CommonEventProps = ActionEvent | ClickEventType;

export interface ClickEventType {
  action?: "click";
  path:
    | keyof typeof EventPathType
    | keyof typeof DirectEventPathType
    | keyof typeof RefundServicePathType
    | keyof typeof MissParlorEventPathType;
  trigger_and_target:
    | EventTriggerAndTargetType[EventPathType]
    | DirectEventTriggerAndTargetType[DirectEventPathType]
    | RefundServiceTriggerAndTargetType[RefundServicePathType]
    | MissParlorEventTriggerAndTargetType[MissParlorEventPathType];
  text?: string;
}

export interface ActionEvent {
  action:
    | "signup"
    | "login"
    | "call"
    | "goi_call"
    | "match_request_complete"
    | "signup_or_login_success"
    | "uv_revisit_modal"
    | "revisit_modal_cta"
    | "revisit_modal_close"
    | "uv_revisit_parlor_modal"
    | "revisit_parlor_modal_cta-question"
    | "revisit_parlor_modal_cta-uv_prlr_regin"
    | "open_parlor50_modal"
    | CompositeEventTypes
    | TrackingServiceEventTypes;
}

export type CompositeEventTypes =
  | "funnel-parlor_rqst-region-signup"
  | "funnel-uv_match_rqst-submit_signup";

export type TrackingServiceEventTypes =
  | "tracking_event-uv_redirect_url"
  | `tracking_event-uv_redirect_url_${"A" | "B" | "AB"}`;

export enum DirectEventPathType {
  "gnb" = "gnb",
  "home" = "home",
  "product_list" = "product_list",
  "product_detail" = "product_detail",
  "registration" = "registration",
  "b2b" = "b2b",
  "introduce" = "introduce",
  "home_honam" = "home_honam",
  "home_dggb" = "home_dggb",
  "content" = "content",
  "feed" = "feed",
  "content_sn_1" = "content_sn_1",
  "content_sn_2" = "content_sn_2",
  "content_care" = "content_care",
  "home_female" = "home_female",
  "coupon" = "coupon",
  "comparison" = "comparison",
  "closed" = "closed",
  "half" = "half",
  "saving" = "saving",
  "toss" = "toss",
}

export enum RefundServicePathType {
  "refund" = "refund",
  "refund_select_payment_period" = "refund_select_payment_period",
  "refund_select_product" = "refund_select_product",
  "refund_result" = "refund_result",
}

export enum MissParlorEventPathType {
  "prlr_fam_main" = "prlr_fam_main",
  "prlr_fam_keyword" = "prlr_fam_keyword",
}
// 본 PagePath는 가급적 GTM상 uv와 동일한 층위를 가져갑니다.
export enum EventPathType {
  "gnb" = "gnb", //gnb -> PC 상단 탭, 모바일 하단 공통
  "gdrwr" = "gdrwr", // global drawer
  "footer" = "footer",
  "parlor" = "parlor",
  "snackbar" = "snackbar",
  "step_guide" = "step_guide",
  "step_guide_parlor" = "step_guide_parlor",
  "parlor_detail" = "parlor_detail",
  "parlor_region" = "parlor_region",
  "parlor_detail_request" = "parlor_detail_request",
  "parlor_backlink_list" = "parlor_backlink_list",
  "facility_detail" = "facility_detail",
  "facility_search" = "facility_search",
  "service" = "service",
  "review_detail" = "review_detail",
  "review" = "review",
  "funeral_prepare" = "funeral_prepare",
  "notice_detail" = "notice_detail",
  "parlor_detail_price" = "parlor_detail_price",
  "parlor_match_request" = "parlor_match_request",
  "parlor_only_match_request" = "parlor_only_match_request",
  "facility_region" = "facility_region",
  "facility_match_request" = "facility_match_request",
  "facility_match_recommendation" = "facility_match_recommendation",
  "match_request" = "match_request",
  "match_request_complete" = "match_request_complete",
  "match_recommendation_detail" = "match_recommendation_detail",
  "match_clsd_req" = "match_clsd_req",
  "mtch_recomm_dtl" = "mtch_recomm_dtl",
  "mtch_recomm_lst" = "mtch_recomm_lst",
  "match" = "match",
  "login" = "login",
  "home" = "home",
  "home_sa_1" = "home_sa_1",
  "home_sa_2" = "home_sa_2",
  "guidebook" = "guidebook",
  "guide" = "guide",
  "guidebook_search" = "guidebook_search",
  "funeral" = "funeral",
  "funeral_detail" = "funeral_detail",
  "funeral_prepare_detail" = "funeral_prepare_detail",
  "consult_list" = "consult_list",
  "my_detail" = "my_detail",
  "my" = "my",
  "gdb_tip" = "gdb_tip",
  "gdb_tip_bfr" = "gdb_tip_bfr",
  "gdb_tip_cvs" = "gdb_tip_cvs",
  "guide_about_parlor_funeral" = "guide_about_parlor_funeral",
  "promotion" = "promotion",
  "guide_content" = "guide_content",
  "match_compare_service" = "match_compare_service",
  "hospice_search" = "hospice_search",
  "hospice_detail" = "hospice_detail",
  "crematorium_detail" = "crematorium_detail",
  "crematorium_region" = "crematorium_region",
  "funeral_cost" = "funeral_cost",
  "infrmatin_price_parlor" = "infrmatin_price_parlor",
  "match_facility_request" = "match_facility_request",
  "consultant_search" = "consultant_search",
  "directors_parlor" = "directors_parlor",
  "sea_burial" = "sea_burial",
  "sea_burial_region" = "sea_burial_region",
  "sea_burial_detail" = "sea_burial_detail",
  "care" = "care",
  "care_detail" = "care_detail",
  "promotion_parlor_50" = "promotion_parlor_50",
  "match_facil_req_prime" = "match_facil_req_prime",
  "info_price_facil" = "info_price_facil",
  "facility_relocate" = "facility_relocate",
}

export interface RefundServiceTriggerAndTargetType {
  refund: "landing_CTA" | "footer_CTA";
  refund_select_product: "footer_CTA" | "sangjo_item" | "sangjo_product_item";
  refund_select_payment_period: "footer_CTA";
  refund_result: "footer_left_CTA" | "footer_right_CTA";
}

export interface MissParlorEventTriggerAndTargetType {
  prlr_fam_main:
    | "nav-info"
    | "nav-uv_mtch_rqst_prlr"
    | "nav-fune-proc"
    | "nav-road"
    | "btn-hwahwan"
    | "btn-goi_call"
    | "btn-uv-htch_rqst_prlr"
    | "footer-uv_mtch_rqst_prlr"
    | "uv-mtch_rqst_prlr"
    | "footer_goi_call"
    | "goi_call";
  prlr_fam_keyword: "";
}

export interface DirectEventTriggerAndTargetType {
  gnb:
    | "uv_home"
    | "uv_premium"
    | "uv_product_list"
    | "menu_close"
    | "diff_sangjo_compare"
    | "uv_b2b"
    | "uv_intro"
    | "uv_content"
    | "contract_check";
  home:
    | "home_promotion-9900"
    | "hero_CTA-uv_registration"
    | "uv_pr"
    | `goi_detail-uv_link_${number}`
    | "card-uv_review_detail";
  saving: "";
  toss: "info_CTA";
  home_honam: "";
  home_dggb: "";
  feed: "";
  comparison: "";
  closed: "";
  content:
    | "tab_funeral"
    | "tab_review"
    | "tab_all"
    | "tab_youtube"
    | "tab_feed"
    | "uv_sn-1"
    | "uv_sn-2"
    | "youtube_1"
    | "youtube_2"
    | "youtube_3"
    | "sn-2-uv_sn-1"
    | "sn-1-uv_sn-2";
  content_sn_1: "";
  content_sn_2: "";
  home_female: "";
  product_list: "uv_detail" | `uv_detail_${string}`;
  product_detail:
    | `opn_mdl`
    | "tp_timer_CTA"
    | "bt_timer_CTA"
    | "fixed_CTA"
    | `send_email`
    | "mdl_submit_btn"
    | `mdl_name_inpt`
    | `mdl_phone_inpt`
    | `mdl_all_chk`
    | `mdl_term_chk`
    | `mdl_prsnl_chk`
    | "mdl_CTA"
    | "mdal_parlor50_CTA";

  registration:
    | "toggle_mode_switch"
    | "btmsht_account_chip"
    | `btmsht_account_chip_${1 | 2 | 3 | 4}`
    | "btmsht_over_account_chip"
    | "all_required_agree_btn"
    | "all_optional_agree_btn"
    | "all_information_info_CTA"
    | "check_product_CTA"
    | "account_select_CTA"
    | "verification_CTA"
    | "address_CTA"
    | "name_info_CTA"
    | "phone_info_CTA"
    | "birthdate_info_CTA"
    | "gender_info_CTA"
    | "user_info_CTA"
    | "contract_CTA"
    | "opn_contract_mdl"
    | "opn_contract_chk_list_mdl"
    | "CTA-uv_home"
    | "retry_payment"
    | "payment_success"
    | "accounts_minus_one"
    | "accounts_plus_one"
    | "accounts_plus_two"
    | "over_accounts_CTA"
    | "failure_CTA"
    | `pay_slct-${string}`
    | "prime_payment_opn"
    | "prime_payment_submit"
    | "payment_account_opn"
    | "go_pay_bfr_account"
    | "go_pay_bfr_pay_slct"
    | "info_tab_trust"
    | "info_tab_product"
    | "all_agree_btn"
    | "go_FAQ"
    | "submit_survey"
    | `uv_feed-${string}`
    | "100_payment_submit"
    | "saving_payment_submit"
    | "uv_100_pay_sheet"
    | "uv_saving_pay_sheet"
    | "CTA-refund_select"
    | "next_survey"
    | "verification_pass"
    | "verification_eziok";
  b2b:
    | "hero_CTA-opn_mdl"
    | "hero_CTA-call"
    | "mdl_cmpnyname_inpt"
    | "mdl_email_inpt"
    | "biz_lite_check"
    | "biz_standard_check"
    | "biz_premium_check"
    | "biz_etc_check";
  introduce: "";
  coupon: "";
  half: "";
  content_care: "";
}

// 개별 버튼 텍스트를 이곳에 다 넣어주세요 ^^
// 버튼텍스트_가는곳 - 가는 곳이 uv에 해당하면 무조건 동일하게 이름 넣기. 다른 경우 맞춰서 (모달오픈, 분기 등등)
export interface EventTriggerAndTargetType {
  match_clsd_req: "";
  match_facil_req_prime: "";
  care_detail: "";
  care: "card-uv_detail" | "tab_checklist";
  step_guide_parlor: "";
  infrmatin_price_parlor: "";
  my: "";
  funeral_cost:
    | "CTA_footer-uv_match_request"
    | "price-uv_company_introduction"
    | "how_goi-uv_company_introduction"
    | "no_more_cost-uv_company_introduction"
    | "CTA_under-uv_match_request"
    | "CTA_price_detail-uv_match_request"
    | "uv_review"
    | "uv_match";
  parlor: "CTA-uv_match_request";
  parlor_backlink_list: "";
  guide: "";
  // "wooribugo";
  gnb:
    | "signup_modal_close"
    | "open_file_request_modal"
    | "recent_review-uv_review_detail"
    | "content-uv_prepare_1"
    | "content-uv_how_about_parlor_funeral"
    | "content-uv_about_parlor_funeral"
    | "content-uv_prepare_2"
    | "content-uv_company_introduction"
    | "content-uv_funeral_cost"
    | "srvc_rcmms-uv_funeral_prepare_1"
    | "my_consult_list-uv_consult_list"
    | "funeral_info-uv_guidebook"
    | "mypage-uv_my"
    | "mtch_rcmms"
    | "logout-trigger_logout"
    | "login-uv_login"
    | "logo-uv_home"
    | "home-uv_home"
    | "funeral-uv_funeral"
    | "review-uv_review"
    | "funeral_or_review"
    | "srvc_rcmms-uv_match_request"
    | "prepare_package-uv_p_p"
    | "discount_btn-uv_p_p"
    | "call-opn_call_mdl"
    | "drawer-mtch_rqst"
    | "drawer-parlor"
    | "drawer-facilities"
    | "drawer-guidebook"
    | "drawer-my"
    | "drawer-service"
    | "drawer-review"
    | "sa_2_service"
    | "sa_2_call_mdl"
    | "hamburger"
    | "funeral-prepare-1"
    | "step-guide"
    | "nav-uv_home"
    | "nav-uv_match_request"
    | "nav-uv_guidebook";
  gdrwr:
    | "opn_qttn_rqst-urgent_call"
    | "opn_qttn_rqst-call"
    | "opn_qttn_rqst-keep_quotation"
    | "qttn_rqst-close_urgent_call"
    | "qttn_rqst-close_call"
    | "qttn_rqst-close_keep_quotation"
    | "qttn_rqst-crm_urgent_call"
    | "qttn_rqst-crm_call"
    | "qttn_rqst-crm_keep_quotation";
  footer:
    | "blog_icon-blog"
    | "youtube_icon-youtube"
    | "instagram_icon-instagram"
    | "naver_post_icon-post"
    | "mypage-uv_my"
    | "cs_center-uv_my"
    | "footer-uv_consultant-search";
  snackbar:
    | "quickcall_mdl-close"
    | "quickcall_mdl-urgent_call-close"
    | "quickcall_mdl-call-close"
    | "ftr_btn_lft-opn_mdl"
    | "ftr_btn_rght-opn_mdl"
    | "urgent_mdl-close"
    | "urgent_mdl_ftr_rght-actn_call"
    | "urgent_mdl_ftr_lft-uv_review"
    | "quick_mdl_ftr_rght-actn_call"
    | "quick_mdl_ftr-urgent_call-one_actn_call"
    | "quick_mdl_ftr-call-one_actn_call"
    | "quick_mdl_ftr_lft-uv_review"
    | "ask_goi-call_goi"
    | "quick_mdl_accordion-opn_dtl"
    | "quick_mdl_accordion-urgent_call-opn_dtl"
    | "quick_mdl_accordion-call-opn_dtl";
  step_guide:
    | "price_compare-uv_match_request"
    | "detail_price-uv_match_request"
    | "initiation-next"
    | "initiation-select_important_factor"
    | "mubinso-match_request_CTA"
    | "mubinso-match_request_fixed"
    | "mubinso-uv_guidebook_mubinso"
    | "mubinso-uv_guidebook_jangji"
    | "yubinso-match_request_CTA"
    | "yubinso-match_request_fixed"
    | "yubinso-uv_review"
    | "yubinso-uv_guidebook_jangji"
    | "onedaybinso-call_fixed"
    | "onedaybinso-call_CTA"
    | "onedaybinso-uv_guidebook_mubinso"
    | "onedaybinso-uv_guidebook_jangji"
    | "prepaid_question-chosun_ilbo"
    | "prepaid_question-ftc_my_sangjo"
    | "prepaid_question-uv_match_request"
    | "prepaid_question-next"
    | "prepaid_question-select_answer"
    | "fcilty_price-uv_stp_gd_dstnce"
    | "fcilty_price-uv_stp_gd_place"
    | "fcilty_price-uv_mtch_rqst"
    | "fcilty_dstnce-uv_stp_gd_price"
    | "fcilty_dstnce-uv_stp_gd_place"
    | "fcilty_dstnce-uv_mtch_rqst"
    | "fcilty_place-uv_stp_gd_price"
    | "fcilty_place-uv_stp_gd_dstnce"
    | "fcilty_place-uv_mtch_rqst";
  parlor_region:
    | "modal_discount_parlor-close"
    | "modal_discount_parlor-uv_parlor_detail"
    | "list-tab_list"
    | "check_discount-open_modal"
    | "service_by_chip-uv_parlor_region_service"
    | "tab_recomm-tab"
    | "tab_parlor_list-tab"
    | "check_discount-uv_match_request"
    | "CTA_btm_total_price-uv_match_request"
    | "CTA_btm_consult-uv_match_request"
    | "CTA_top-uv_match_request"
    | "parlor_item-uv_parlor_detail"
    | "select_sigungu-select"
    | "sigungu_parlors-uv_sigungu_parlors"
    | "sido_parlors-uv_sido_parlors"
    | "brdcrmb-uv_mtch_recomms"
    | "brdcrmb-uv_parlor_region_service"
    | "brdcrmb-uv_sido_parlors"
    | "brdcrmb-uv_sigungu_parlors"
    | "banner_CTA-uv_step_guide"
    | "sigungu_parlors-uv_sido_parlors";
  directors_parlor:
    | "detail_CTA-uv_match_request"
    | "list_CTA-uv_match_request"
    | "directors_parlor_list-profle_and_review";
  parlor_detail:
    | "parlor_detail-go_PARLOR30V2_contract"
    | "image_banner-contract_parlor"
    | `estimate_card_${string}-uv_match_request`
    | "detail_compare_estimate"
    | "estimate_CTA-uv_match_request"
    | "close-chatbot"
    | "chatbot-open"
    | "chatbot_answer-checklist"
    | "chatbot-call"
    | "chatbot_answer-parlor_compare"
    | "chatbot_answer-funeral_process"
    | "chatbot_answer-uv_match_request"
    | "chatbot_answer-what_to_do"
    | "funeral_director-uv_funeral_prepare_1"
    | "funeral_director-profile_and_review"
    | "funeral_diretor-uv_match_request"
    | "image_banner-uv_how_about_parlor_funeral"
    | "bagaji-call"
    | "shrtcut_btn-second"
    | "shrtcut_btn-file_request"
    | "shrtcut_btn-uv_how_about_parlor_funeral"
    | "shrtcut_btn-funeraldirector_uv_mtch_rqst"
    | "shrtcut_btn-yugol_call"
    | "shrtcut_btn-jedan_call"
    | "shrtcut_btn-uv_funeral_expense"
    | "shrtcut_btn-uv_review"
    | "package_selection-select_package"
    | "package_selection"
    | "package-uv_service"
    | "package-call"
    | "package_reserve-uv_match_request"
    | "detali-uv_service"
    | "chip-sangjo_selector"
    | "tab-expected-detail-price"
    | "sido-compare-test"
    | "sigungu-compare-test"
    | "visit_test1_flower"
    | "visit_test2_info"
    | "deceased_test1_call"
    | "deceased_test2_process_chat"
    | "deceased_test3_price"
    | "deceased_test0_match_request"
    | "deceased_test4_match_request"
    | "uv_tip_parlor_price"
    | "call_discount"
    | "CTA-uv_parlor_information_price"
    | "footer_floating-checklist"
    | "file_request_mdl-number"
    | "file_request_mdl-email"
    | "file_request_mdl-close"
    | "file_request_mdl-try_submit"
    | "file_request_mdl-submit_success"
    | "step_guide-CTA_uv_match_request"
    | "step_guide-fixed_price_detail"
    | "step_guide_CTA_right-uv_match_request"
    | "step_guide_CTA_left-call"
    | "step_guide-service_detail"
    | "price_goi_chip"
    | "price_parlor_chip"
    | "CTA_custom_cost"
    | "CTA_accurate_cost"
    | "uv_funeral_cost"
    | "uv_compnay_introduction"
    | "CTA-uv_match_request_real_link"
    | "selected_review-uv_review"
    | "price-tab_review"
    | "review-button_category"
    | "review-uv_match_request"
    | "review-call"
    | "review_detail"
    | "special_a1-answer"
    | "special_a1-call"
    | "special_a2-call_close"
    | "special_a2-call"
    | "special_a2-match_request"
    | "special_a1-match_request"
    | "special_a3-answer"
    | "special_a4-call"
    | "special_a4-match_request"
    | "special_a4_2-match_request"
    | "reasonable_funeral"
    | "call-sangjo_service"
    | "call-binso_discount"
    | "call-sangbok_daeyeo"
    | "modal_A-uv_how_about_parlor_funeral"
    | "step_guide_fixed_button"
    | "modal-close"
    | "compare_modal-discount_other"
    | "match_request-continue"
    | "discount_detail-open_discount_modal"
    | "modal_discount-CTA"
    | "modal_discount-close"
    | "quick_consult-quickcall_modal"
    | "footer_lft-quickcall_modal"
    | "compare_modal_ftr_lft-uv_about_parlor_funeral"
    | "compare_modal_ftr_rght-uv_parlor_region"
    | "goi_srvc_recomm-uv_match_request"
    | "see_detail-uv_about_parlor"
    | "common_review_section"
    | "CTA"
    | "CTA-onestop"
    | "CTA-parlor"
    | "CTA-facility"
    | "modal_detail_CTA-google_form_package"
    | "modal_seemore-google_form_package"
    | "modal_seemore"
    | "modal_detail_CTA"
    | "modal_detail_CTA-uv_guide_about_parlor_funeral"
    | "modal_seemore-uv_guide_about_parlor_funeral"
    | "modal_recommendation-uv_guide_about_parlor_funeral"
    | "modal_recommendation_CTA"
    | "parlor_review_mdl-close"
    | "parlor_review_mdl_ftr_rght-uv_match_request"
    | "parlor_review_mdl_ftr_left-uv_funeral_detail"
    | "review_CTA-mdl_review_parlor"
    | "sigungu_parlors_price_dtl-uv_sigungu_parlors"
    | "brdcrmb-uv_mtch_recomms"
    | "brdcrmb-uv_parlor_region_service"
    | "brdcrmb-uv_sido_parlors"
    | "brdcrmb-uv_sigungu_parlors"
    | "modal_close-close_top"
    | "modal_close-close_bottom"
    | "modal_detail_CTA-uv_funeral_prepare_1"
    | "uv_funeral_prepare_1"
    | "uv_funeral_prepare_2"
    | "uv_funeral_prepare_5"
    | "modal_seemore-uv_guidebook"
    | "modal_recommendation-uv_match_request"
    | "post_or_check_review-post_or_manual_review"
    | "copy-copy_address"
    | "info-tab_info"
    | "price-tab_price"
    | "packageType0_chip-expected_price"
    | "packageType1_chip-expected_price"
    | "packageType2_chip-expected_price"
    | "packageType_details-0"
    | "packageType_details-1"
    | "packageType_details-2"
    | "check_discount-uv_match_request"
    | "check_total_funeral_cost_CTA-uv_match_request"
    | "why_expensive-uv_guidebook_tip_before_funeral_expense"
    | "footer_bugo-bugo"
    | "footer_compare-open_compare_modal"
    | "compare_modal-close_top"
    | "compare_modal_others-uv_parlor_detail"
    | "brdcrmb-uv_sido_parlors"
    | "brdcrmb-uv_sigungu_parlors"
    | "prepare_package-uv_p_p"
    | "send_flower_email"
    | "send_sangbok_email"
    | "send_benefit_request_email"
    | "benefit_request_selected"
    | "benefit_request_submit"
    | `content-uv_${string}`;
  parlor_detail_request: "pyeong-answer" | "";
  parlor_detail_price:
    | "tab-price"
    | "discount_btn-uv_mtch_rqst_prlr"
    | "brdcrmb-uv_parlor_detail"
    | "call-hwahwan_bonaegi";
  facility_detail:
    | "brdcrmb-uv_mtch_recomms"
    | "details-uv_match_request"
    | "details-uv_mtch_rqst_fclty"
    | "banner_CTA-uv_p_p"
    | "modal_detail_close"
    | "modal_detail_CTA"
    | "modal_header_close"
    | "chk_total_cost_CTA-uv_home"
    | "chk_total_cost_CTA-uv_mtch_rqst"
    | "only_jangji_modal-tab_onestop"
    | "only_jangji_modal-continue"
    | "submit_conversion"
    | "jangji_only-answer"
    | "jangji_status-answer"
    | "jangji_type-answer"
    | "jangji_important_factor-answer"
    | "jangji_desired_service-answer"
    | "facility_funeral_sido-answer"
    | "facility_funeral_sigungu-answer"
    | "facility_sido-answer"
    | "facility_sigungu-answer"
    | "match_request-continue"
    | "compare_CTA-uv_mtch_rqst"
    | "step_guide_fixed_button"
    | "top_banner_CTA-uv_step-guide"
    | "facility-select_important_factor"
    | "facility_info-uv_facilities-search"
    | "visit_consult-call"
    | "chk_total_cost_CTA-uv_mtch_facil_req"
    | "information_price_top_CTA-uv_mtch_facility_rqst"
    | "information_price_bottom_CTA-uv_mtch_facility_rqst"
    | "call_discount"
    | "top_CTA-uv_mtch_facility_rqst"
    | "CTA-uv_facil_relocate";
  facility_match_recommendation:
    | "check_previous_result-uv_consult-list"
    | "recommend-uv_match_request"
    | "quickcall";
  facility_search: "search_result-uv_facility";
  review_detail:
    | "review_detail-other_review_detail"
    | "funeral_detail_card-uv_funeral_detail"
    | "recommend_other_funerals_card-uv_home"
    | "back_to_review_list-uv_review_list";
  review:
    | "pbanner-call"
    | "pbanner-uv_match_request"
    | "pbanner-b1_prime"
    | "pbanner-b2_prime"
    | "pbanner-b3_premium"
    | "banner_CTA-uv_match_request"
    | "selector_sangjo-open_drawer"
    | "selector_sangjo_service-open_drawer"
    | "drawer_sangjo-tab"
    | "drawer_sangjo-service-tab"
    | "drawer_sangjo-detail"
    | "drawer_sangjo-service-detail"
    | "drawer_close"
    | "drawer_complete-select"
    | "check_goi_service-uv_home"
    | "review-uv_review_detail"
    | "top_review_section-uv_review_detail"
    | "active_clicks"
    | "rqst_btn-uv_mtch_rqst"
    | "call_btn-call"
    | "review_page-other_page";
  notice_detail:
    | "kakao_start-uv_login"
    | "go_application-open_kakao_chat"
    | "confirm-uv_login";
  parlor_only_match_request:
    | "parlor_price-answer"
    | "parlor_factor-answer"
    | "submit_conversion";
  parlor_match_request:
    | "parlor_price-answer"
    | "parlor_factor-answer"
    | "status-answer"
    | "sido-answer"
    | "sigungu-answer"
    | "condolers-answer"
    | "submit_conversion"
    | "mdl_check_result";
  facility_match_request:
    | "only_jangji_modal-tab_onestop"
    | "only_jangji_modal-continue"
    | "temp_goicall"
    | "submit_conversion"
    | "jangji_only-answer"
    | "jangji_status-answer"
    | "jangji_type-answer"
    | "jangji_important_factor-answer"
    | "jangji_desired_service-answer"
    | "facility_funeral_sido-answer"
    | "facility_funeral_sigungu-answer"
    | "facility_sido-answer"
    | "facility_sigungu-answer"
    | "mdl_check_result";
  match_request:
    | "submit_detail_request-non_member"
    | "footer_left-submit"
    | "footer_right-submit"
    | "compare_modal-continue"
    | "compare_modal-close"
    | "sangjo_compare_button"
    | "tab_all"
    | "tab_parlor"
    | "tab_facility"
    | "open_before_leave_modal"
    | "status-answer"
    | "sido-answer"
    | "sigungu-answer"
    | "condolers-answer"
    | "recommendation_memo-option"
    | "check_result-uv_login"
    | "check_previous_result-uv_consult-list"
    | "before_leave_modal_quit-go_back"
    | "before_leave_modal-continue"
    | "quick_cnslt-opn_urgnt_mdl"
    | "mdl_check_result"
    | "learn_more-opn_match_content_mdl"
    | "submit_conversion"
    | "close_main_modal"
    | "prepare_package-uv_p_p"
    | "mdl_select-premium"
    | "urgent-memo-option"
    | "urgent-memo-call"
    | "submit-urgent-memo"
    | "worry-answer";
  match_compare_service:
    | "is_prepaid_answer"
    | "company_name-answer"
    | "product_name-answer"
    | "other_info-answer";
  match_request_complete: "allow_call-toggle_button";
  match:
    | "recommendation-uv_match_request"
    | "recommendation_fixed_button-uv_match_request";
  mtch_recomm_dtl:
    | "jangji_discount_call"
    | "ftr_btn_text_cnslt-kakao_chat"
    | "facility_request-uv_only_facility_match_request"
    | "consult_jangji-uv_match_facility_recommendation"
    | "parlor_request-uv_only_parlor_match_request"
    | "quickcall"
    | "why_parlor_recommended"
    | "banner_CTA-uv_p_p"
    | "arrw_dwn-opn_calculator"
    | "ftr_btn_cmn"
    | "mdl_cnslt_2_rsrv-need_urgent"
    | "mdl_cnslt_2_rsrv-timeslot"
    | "cnslt_rsrv_mdl_close"
    | "mdl_only_rsrv_2_make_rsrv-apply"
    | "mdl_cnslt_2_apply-uv_cnslt_dtl"
    | "mdl_only_rsrv_1-uv_rv_list"
    | "mdl_only_rsrv_1-rsrv_cmplt"
    | "mdl_cnslt_1_select_rsrv-step_2"
    | "mdl_only_rsrv_2-call_goi"
    | "qck_call_mdl-uv_rvs"
    | "qck_call_mdl-call_goi"
    | "qck_call_mdl-close"
    | "qck_call_mdl_accordion-opn_dtl"
    | "mdl_only_rsrv_1_select_rsrv-step_2"
    | "mdl_only_rsrv_2_apply-uv_cnslt_dtl"
    | "mdl_cnslt_1_cnslt_ongoing-uv_cnslt_dtl"
    | "mdl_cnslt_rsrv_mdl_close"
    | "mdl_cnslt_1_no_call-uv_cnslt_dtl"
    | "mdl_cnslt_2_apply-uv_cnslt_dtl"
    | "mdl_cnslt_1_no_call-apply_btn"
    | "oth_svc_mdl_uv_recomm_more"
    | "oth_svc_mdl_close"
    | "oth_svc_mdl-uv_mtch_recomm_lst"
    | "ftr_btn_qck_cnslt-opn_mdl_qck_call"
    | "ftr_btn_cmpr-uv_mtch_recomms_list"
    | "ftr_btn_more-opn_mdl_oth_svc"
    | "ftr_btn_qck_cnslt-uv_cslt_dtl"
    | "ftr_btn_rsrv_cnslt-opn_rsrv_mdl"
    | "cnslt_only_rsrv_mdl_close"
    | "ftr_btn_only_rsrv_cnslt-opn_rsrv_mdl"
    | "calculator_close"
    | "calc_init-cls_calc"
    | "price_chck-open_mdl_pricecheck"
    | "mdl_price_chck-uv_review"
    | "mdl_price_chck-check_price"
    | "mdl_price_chck-close"
    | "mdl_calc_mdl_parlor-done"
    | "mdl_calc_mdl_parlor_ftr_dontknow-select_need_cnslt"
    | "mdl_calc_mdl_parlor_ftr_select-cls_mdl"
    | "mdl_calc_mdl_jangji-done"
    | "mdl_calc_mdl_jangji_ftr_dontknow-select_need_cnslt"
    | "mdl_calc_mdl_jangji_ftr_select-cls_mdl"
    | "mdl_calc_mdl_jangji-jangji_type"
    | "calc_select-opn_mdl_parlor"
    | "calc_select-opn_mdl_jangji"
    | "youtube-video_played"
    | "uv_promotion_service"
    | "uv_prime"
    | "CTA_benefit"
    | `CTA_benefit_${"prime" | "promotion_service"}`;
  mtch_recomm_lst:
    | "CTA-uv_match_request"
    | "CTA-uv_review"
    | "recomm_more-uv_mtch_recomm_dtl"
    | "no_result-uv_match_request"
    | "no_result-uv_review"
    | "btn_dtl-uv_match_recomm_dtl";
  match_recommendation_detail:
    | "private_jangji-call"
    | "hwajang-call"
    | "tab_funeral_prepare_youtube"
    | "tab_review"
    | "tab_funeral_director"
    | "sangjo_compare-report_result_click"
    | "sangjo_compare-call"
    | "main_recommendation_banner-detail_modal"
    | "modal_detail_close-close"
    | "review_detail-uv_review_detail"
    | "content_funeral_director-uv_funeral_prepare_1"
    | "content_how_to_choose-uv_guidebook_tip_before_5"
    | "footer_other_service-open_modal_other_service"
    | "modal_other_service_button_1-uv_home"
    | "modal_other_service_button_2-uv_notice_1"
    | "modal_other_service_button_3-uv_guidebook_summary_before"
    | "modal_other_service_close-close_modal"
    | "footer_start_consult-open_modal_consult"
    | "modal_consult_no_call-uv_consult_detail"
    | "modal_consult_select_reservation-modal_step_reservation"
    | "modal_consult_select_reservation-need_urgent"
    | "modal_consult_select_reservation-select_timeslot"
    | "modal_consult_select_reservation_apply_button-uv_consult_detail"
    | "modal_consult_select_reservation_urgent_call-uv_consult_detail"
    | "modal_consult-close"
    | "footer_ongoing_consult-uv_consult_detail";
  login:
    | `login_${string}`
    | `signup_${string}`
    | "nokakao_modal-close"
    | "nokakao-open_nokakao_modal"
    | "kakao_start-kakao_login"
    | "nokakao_start-nokakao_login"
    | "drawer_dormant_close-close_drawer"
    | "drawer_dormant_revert-revert_dormancy"
    | "modal_confirm-close_modal"
    | "footer_ongoing_consult-uv_consult_detail"
    | "call"
    | "chat";
  home:
    | "CTA_banner-call"
    | "CTA_banner-uv_match_request"
    | "pyojun_service-uv_service"
    | "main_banner_recommendation-uv_match"
    | "main_banner_prepare_atoz-uv_funeral_prepare_1"
    | "main_banner_compare_sangjo-uv_funeral_prepare_2"
    | "main_banner_see_difference-uv_funeral_prepare_3"
    | "banner_recommendation_button-uv_match_request"
    | "list_recommendation-uv_funeral_detail"
    | "list_price_satisfaction-uv_funeral_detail"
    | "list_review-uv_funeral_detail"
    | "list_professionalism-uv_funeral_detail"
    | "banner_guidebook_button-uv_notice_1"
    | "review_section_review_detail-uv_review_detail"
    | "review_section_all_reviews-uv_review"
    | "why_goi_trust_button-uv_funeral_prepare_3"
    | "how_to_use_goi-uv_funeral_prepare_1"
    | "drawer_recommendation_close-close"
    | "drawer_recommendation_button-uv_match_request"
    | "match_CTA_bottom-uv_match_request"
    | "review_button-uv_review"
    | "search_service-uv_funeral"
    | "complimentary_quotation_button-uv_match_request"
    | "top-status-answer"
    | "top-sido-answer"
    | "top-sigungu-answer"
    | "top-condolers-answer"
    | "bt-status-answer"
    | "bt-sido-answer"
    | "bt-sigungu-answer"
    | "bt-condolers-answer"
    | "top-submit_conversion"
    | "bt-submit_conversion"
    | "top-alredy_qutatin-uv_mch_recomm"
    | "bt-alredy_qutatin-uv_mch_recomm"
    | "top-alredy_qutatin-uv_bnf_prepare"
    | "bt-alredy_qutatin-uv_bnf_prepare"
    | "prviw_mtch-opn_mdl"
    | "prces_btn-uv_gdb_fnrl_prces"
    | "director_consult_btn-opn_mdl"
    | "goi_director_good_btn-opn_mdl"
    | "all_review_btn-uv_review"
    | "pr-uv_pr"
    | "intrviw-play_youtube"
    | "frst_prpre-uv_fnrl_prpre_1"
    | "frst_prpre-uv_fnrl_prpre_2"
    | "frst_prpre-uv_fnrl_prpre_3"
    | "qik_cal_btn-opn_mdl"
    | "24hour_btn-opn_mdl"
    | "goi_srvice-uv_srvice"
    | "ftr_circle_btn-uv_guidebook"
    | "ftr_circle_btn-uv_qna"
    | "ftr_circle_btn-uv_mtch_rqst"
    | "ftr_circle_btn-uv_parlor"
    | "ftr_circle_btn-uv_facility"
    | "ftr_circle_btn-uv_bugo"
    | "shrtcut_btn_mtch_rqst-uv_mtch_rqst"
    | "shrtcut_btn_parlor-uv_mtch_rqst"
    | "shrtcut_btn_facility-uv_mtch_rqst"
    | "shrtcut_btn_compare-uv_mtch_rqst"
    | "service_tab-binso0"
    | "service_tab-binso50"
    | "service_tab-binso100"
    | "service_tab-binso200"
    | "24hour_standby-opn_call_mdl"
    | "24hour_standby-uv_mtch_rqst"
    | "step_guide_fixed_button"
    | "top-consult-uv_funeral_prepare_1"
    | "bt-consult-uv_funeral_prepare_1"
    | "shrtcut_btn_intro-uv_cmpny_intro"
    | "shrtcut_btn_service-uv_service"
    | "shrtcut_btn_price-move_home_service"
    | "shrtcut_btn_prcss-uv_gidbok_prcss"
    | "shrtcut_btn_prpre-uv_fnrl_prpre_1"
    | "question-opn_mdl"
    | "send_email_mdl-close"
    | "send_email_mdl-btn"
    | "send_question_email"
    | "layout_CTA-uv_mtch_rqst"
    | "goi_service-uv_service"
    | "goi_msg-uv_fnrl_pre"
    | "goi_msg-uv_service"
    | `banner_tab_${0 | 1}`
    | `banner_CTA-uv_${"promotion_service" | "prime"}`;
  home_sa_1: "";
  home_sa_2:
    | "call_mdl-header_close"
    | "call_mdl-opn_schedule"
    | "call_mdl-call"
    | "scedul_mdl-close"
    | "scedul_mdl-send_btn"
    | "send_email"
    | "service_binso0"
    | "service_binso50"
    | "service_binso100"
    | "service_binso200";
  guidebook:
    | "ex_CTA_book-uv_notice_1"
    | "CTA_bk-uv_notice_1"
    | "uv_service"
    | "uv_about_parlor_funeral"
    | "call-yugolham"
    | "funeral_expense_youtube-video_played"
    | "funeral_expense_youtube_btm-uv_match_request"
    | "search-uv_guidebook_search"
    | "info-uv_guidebook_summary"
    | "tab_all-tab_all"
    | "tab_qna-tab_qna"
    | "chip_guidebook-before"
    | "chip_guidebook-ongoing"
    | "chip_guidebook-after"
    | "chip_guidebook-etc"
    | "content_detail-uv_guidebook_tip_before"
    | "content_detail-uv_guidebook_tip_ongoing"
    | "content_detail-uv_guidebook_tip_after"
    | "content_detail-uv_guidebook_tip_etc"
    | "post_qna_button-uv_guidebook_edit"
    | "post_qna_modal-post_question"
    | "want_call-call"
    | "chip_qna-qna_type_text"
    | "qna_detail_card-open_qna_modal"
    | "qna_modal-share"
    | "qna_modal-scrap"
    | "CTA_uv_guidebook"
    | "bottom-CTA_link_to_prime";
  guidebook_search:
    | "tab_all-tab_all"
    | "tab_qna-tab_qna"
    | "content_detail-uv_guidebook_tip_before"
    | "content_detail-uv_guidebook_tip_ongoing"
    | "content_detail-uv_guidebook_tip_after"
    | "content_detail-uv_guidebook_tip_etc"
    | "qna_detail_card-open_qna_modal"
    | "qna_modal-share"
    | "qna_modal-scrap";
  gdb_tip:
    | "banner_CTA-checklist"
    | "youtube_btm-uv_youtube"
    | "youtube-video_played"
    | "footerCTA-uv_guide"
    | "footerCTA_fune_help"
    | "fune_help-uv_mth_req"
    | "fune_help-open_question"
    | "btm_fixed_CTA-uv_mth_req"
    | "btm_fixed_CTA-checklist"
    | `breadcrumb-uv_${string}`
    | `${string}-CTA_${number}`;
  gdb_tip_bfr:
    | "ex_CTA_total-uv_match_request"
    | "ex_fix_btn-uv_qna"
    | "ex_fix_btn-uv_match_request"
    | "ex_mdl_review-uv_review"
    | "ex_mdl_btn-uv_qna"
    | "ex_mdl_btn-uv_match_request"
    | "ex-status-answer"
    | "ex-sido-answer"
    | "ex-sigungu-answer"
    | "ex-condolers-answer"
    | "ex-alredy_qutatin-uv_mch_recomm"
    | "ex-alredy_qutatin-uv_bnf_prepare"
    | "ex-CTA"
    | "ex-youtube-video_played"
    | "ex-youtube_btm-uv_match_request";
  gdb_tip_cvs:
    | "CTA_total-uv_match_request"
    | "CTA_consult-uv_match_request"
    | "fix_btn-opn_mdl_other_service"
    | "fix_btn-opn_mdl_consult"
    | "mdl_other_service-uv_match_request"
    | "mdl_other_service-uv_funeral"
    | "mdl_other_service-uv_guidebk_qna"
    | "mdl_other_service-close_modal"
    | "mdl_consult-close_modal"
    | "mdl_consult-uv_guidebk_qna"
    | "mdl_consult-call_goi";
  funeral:
    | "banner_CTA-uv_match_request"
    | "tab_service-tab_service"
    | "tab_funeral-tab_funeral"
    | "filter_button-open_filter"
    | "service_chip_premium-view_premium"
    | "service_chip_without_binso-view_without_binso"
    | "service_chip_parlor_discount-view_parlor_discount"
    | "service_chip_filter-open_filter"
    | "filter_result_non-open_filter"
    | "service_list-uv_funeral_detail"
    | "funeral_chip-view_recommendation"
    | "funeral_chip-view_price_satisfaction"
    | "funeral_chip-view_review"
    | "funeral_chip-view_professionalism"
    | "list_recommendation-uv_funeral_detail"
    | "list_price_satisfaction-uv_funeral_detail"
    | "list_review-uv_funeral_detail"
    | "list_professionalism-uv_funeral_detail"
    | "more_recommend-view_funeral_tab"
    | "more_recommend-view_service_tab"
    | "more_recommend-uv_match_request"
    | "filter-condolers_0"
    | "filter-condolers_50"
    | "filter-condolers_100"
    | "filter-condolers_200"
    | "filter-price"
    | "filter-policies_1"
    | "filter-policies_2"
    | "filter-policies_3"
    | "filter-category_premium"
    | "filter-category_without_binso"
    | "filter-category_burial"
    | "filter-category_eco"
    | "filter-category_parlor_discount"
    | "filter-reset"
    | "filter-view_result"
    | "active-clicks";
  funeral_detail:
    | "tab_review-tab_review"
    | "tab_service-tab_service"
    | "tab_intro-tab_intro"
    | "tab_faq-tab_faq"
    | "post_review-uv_funeral_detail_review_post"
    | "select_order-select_order"
    | "review_detail-uv_review_detail"
    | "service_chip-view_service"
    | "service_card_see_details-service_detail"
    | "service_compare_price_see_details-service_detail";
  funeral_prepare_detail:
    | "recommendation-uv_match_request"
    | "consult_button-uv_home";
  consult_list:
    | "tab_consultlist-tab_consultlist"
    | "tab_match-tab_match"
    | "sangjo_consult_detail_card-uv_consult_detail"
    | "sangjo_consult_detail_callchat_consult-uv_consult_detail"
    | "sangjo_consult_detail_service-uv_funeral_detail"
    | "sangjo_match_detail-uv_consult_detail";
  my_detail: "";
  service:
    | `${string}_fixed-prime_start_contract`
    | `${string}-prime_start_contract`
    | "go_detail_top-premium"
    | "go_detail_btm-premium"
    | "premium_apply-open_consult"
    | "copy"
    | "uv_match_recommendation_detail"
    | "tab_type-view_type"
    | "tab_step-view"
    | "dropdown_deduction-view"
    | "dropdown_addons-view"
    | "pr-uv_pr"
    | "review-uv_review"
    | "premium_apply_fixed-open_consult";
  guide_content:
    | "CTA_TOP-open-goi-consult-modal"
    | "open-goi-consult-modal"
    | "detail_1-uv_service"
    | "footer_lft-uv_home"
    | "footer_rght-open-goi-consult-modal";
  guide_about_parlor_funeral:
    | "bagaji-call"
    | "direct-call"
    | "estimate-uv_match_request"
    | "CTA_TOP-uv_match_request"
    | "summary_1-toggle"
    | "summary_2-toggle"
    | "summary_3-toggle"
    | "summary_4-toggle"
    | "detail_1-uv_service"
    | "detail_2-uv_match_request"
    | "detail_3-uv_funeral_detail_foreverlife"
    | "detail_3-open-goi-consult-modal"
    | "detail_4-uv_match_request"
    | "footer_lft-open-goi-consult-modal"
    | "footer_rght-uv_match_request";
  promotion:
    | "sign_up-uv_login"
    | "discount_register-open_mdl"
    | "select_service"
    | "send_email";
  hospice_detail:
    | "back-uv_hsps_serch"
    | "tab-info_tab"
    | "tab-facility_tab"
    | "tab-review_tab"
    | "CTA_call"
    | "CTA_uv_prime";
  hospice_search:
    | "dropdown-selct_sido"
    | "chips-selct_srvic_type"
    | "detail_card-uv_hospice_detail";
  funeral_prepare:
    | "frst_bt_btn-uv_review"
    | "frst_bt_btn-uv_mtch_rqst"
    | "scnd_here_click-uv_fnerl_prpre_1"
    | "scnd_bt_btn-uv_fnerl_prpre_1";
  crematorium_detail:
    | "modal-open"
    | "modal-close"
    | "modal-call"
    | "breadcrumb-uv_mtch_recmndtin"
    | "total_CTA-uv_home"
    | "copy-copy_address"
    | "reserve_CTA-call";
  crematorium_region:
    | "modal-open"
    | "modal-close"
    | "modal-call"
    | "sido-dropdown"
    | "recommend-tab"
    | "list-tab"
    | "detail-uv_crematorium_detail";
  match_facility_request: "carousel_item" | `carousel_item_${number}`;
  consultant_search: "search_btn-search";
  facility_region:
    | "select_sido-uv_region_sido"
    | "anthr_facil-uv_facil_region"
    | "card-uv_facility"
    | "no_facility-uv_anthr_type"
    | "no_facility-uv_sido_region";
  sea_burial: "";
  sea_burial_region: "";
  sea_burial_detail: "";
  promotion_parlor_50: "call" | "uv_promotion_service_family";
  info_price_facil:
    | "CTA"
    | "CTA-uv_mtch_facil_req"
    | "bottom-CTA-uv_mtch_facility_rqst";
  facility_relocate: "submit_success";
}

export enum HackleEventName {
  "회원가입" = "custom_sign_up",
  "로그인/회원가입 클릭" = "click_login_register",
  "로그인/회원가입 진입(Drawer) 클릭" = "click_drawer_login_register_enter",
  "로그인/회원가입 진입(내 장례) 클릭" = "click_mypage_login_register_enter",
  "장례식장 할인받기 클릭" = "click_parlor_detail_go_discount",
  "장례식장 할인여부 확인하기 클릭" = "click_parlor_detail_check_discount",
  "장례식장 상세 카카오톡 문의 클릭" = "click_parlor_detail_kakaotalk",
  "견적 요청서 작성 페이지 방문" = "uv_match_request",
  "내 맞춤 견적 페이지 방문" = "uv_consult_list_match",

  "견적 요청 - 장례준비긴급도 선택" = "click_match_request_1_status",
  "견적 요청 - 장지결정상태 선택" = "click_match_request_2_jangji_status",
  "견적 요청 - 자택/병원 주소 선택" = "click_match_request_3_location",
  "견적 요청 - 자택/병원 선택" = "click_match_request_3_0_location_choice",
  "견적 요청 - 장례 시도 선택" = "click_match_request_4_sido",
  "견적 요청 - 장례 시군구 선택" = "click_match_request_5_sigungu",
  "견적 요청 - 우대사항 선택" = "click_match_request_6_preferential",
  "견적 요청 화면 전환 클릭" = "click_match_request_next_conversion",
  "견적 요청 - 화장 여부 선택" = "click_match_request_7_hwajang",
  "견적 요청 - 빈소 여부 선택" = "click_match_request_8_needs_binso",
  "견적 요청 - 조문객 수 선택" = "click_match_request_9_condolers_count",
  "견적 요청 제출 클릭" = "click_match_request_submit_conversion",

  "견적 콘텐츠 - 상단 버튼 클릭" = "click_match_content_click_top",
  "견적 콘텐츠 - 고정 버튼 클릭" = "click_match_content_click_fixed",

  "장례식장 상세 모달 A - 상단 닫기" = "click_parlor_detail_modal_A_close_top",
  "장례식장 상세 모달 A - 하단 닫기" = "click_parlor_detail_modal_A_close_bottom",
  "장례식장 상세 모달 A - 자세히 알아보기" = "click_parlor_detail_modal_A_go_detail",
  "장례식장 상세 모달 A - 추천 받기" = "click_parlor_detail_modal_A_go_match_request",
  "장례식장 상세 모달 A - 관련 내용 더 알아보기" = "click_parlor_detail_modal_A_go_guidebook",

  "장례식장 상세 푸터 - 부고장" = "click_parlor_detail_footer_bugo",
  "장례식장 상세 푸터 - 비교하기" = "click_parlor_detail_footer_compare",
  "장례식장 상세 푸터 - 모달 닫기" = "click_parlor_detail_footer_close",
  "장례식장 상세 푸터 - 다른 장례식장 클릭" = "click_parlor_detail_footer_other_parlors",

  "가이드북 모달 A - 상단 닫기" = "click_guidebook_modal_A_close_top",
  "가이드북 모달 A - 하단 닫기" = "click_guidebook_modal_A_close_bottom",
  "가이드북 모달 A - 가이드북 받기" = "click_guidebook_modal_A_go_notice",

  "가이드북 신청 - 회원가입" = "click_notice_1_signup",
  "가이드북 신청 - 신청" = "click_notice_1_go_chat",

  "홈 배너 - 상단 클릭" = "click_home_banner_first",
  "홈 배너 - 하단 클릭" = "click_home_banner_second",

  "내 맞춤 견적 - 콘텐츠 클릭" = "click_list_match_click_content",

  "카테고리 고객 10명 중 9명이 추천한 상조 - 0" = "click_home_category_first_1",
  "카테고리 고객 10명 중 9명이 추천한 상조 - 1" = "click_home_category_first_2",
  "카테고리 고객 10명 중 9명이 추천한 상조 - 2" = "click_home_category_first_3",
  "카테고리 고객 10명 중 9명이 추천한 상조 - 3" = "click_home_category_first_4",
  "카테고리 고객 10명 중 9명이 추천한 상조 - 4" = "click_home_category_first_5",

  "카테고리 가격 만족도가 높은 상조 - 0" = "click_home_category_second_1",
  "카테고리 가격 만족도가 높은 상조 - 1" = "click_home_category_second_2",
  "카테고리 가격 만족도가 높은 상조 - 2" = "click_home_category_second_3",
  "카테고리 가격 만족도가 높은 상조 - 3" = "click_home_category_second_4",
  "카테고리 가격 만족도가 높은 상조 - 4" = "click_home_category_second_5",
  "카테고리 가격 만족도가 높은 상조 - 5" = "click_home_category_second_6",
  "카테고리 가격 만족도가 높은 상조 - 6" = "click_home_category_second_7",
  "카테고리 가격 만족도가 높은 상조 - 7" = "click_home_category_second_8",
  "카테고리 가격 만족도가 높은 상조 - 8" = "click_home_category_second_9",
  "카테고리 가격 만족도가 높은 상조 - 9" = "click_home_category_second_10",

  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 0" = "click_home_category_third_1",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 1" = "click_home_category_third_2",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 2" = "click_home_category_third_3",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 3" = "click_home_category_third_4",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 4" = "click_home_category_third_5",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 5" = "click_home_category_third_6",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 6" = "click_home_category_third_7",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 7" = "click_home_category_third_8",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 8" = "click_home_category_third_9",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 9" = "click_home_category_third_10",
  "카테고리 진정성에 감동 받은 후기가 많은 상조 - 10" = "click_home_category_third_11",

  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 0" = "click_home_category_fourth_1",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 1" = "click_home_category_fourth_2",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 2" = "click_home_category_fourth_3",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 3" = "click_home_category_fourth_4",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 4" = "click_home_category_fourth_5",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 5" = "click_home_category_fourth_6",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 6" = "click_home_category_fourth_7",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 7" = "click_home_category_fourth_8",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 8" = "click_home_category_fourth_9",
  "카테고리 전문성을 칭찬하는 후기가 많은 상조 - 9" = "click_home_category_fourth_10",

  "장례준비1 - 역경매 유도" = "click_funeral-prepare_match-request",

  "장례식장 상세 - 주소 복사" = "click_parlor_detail_copy_address",
  "장례식장 상세 - 후기 보기" = "click_parlor_detail_go_review",
  "장례식장 상세 - 탭 비용 클릭" = "click_parlor_detail_tab_price",
  "장례식장 상세 - 탭 정보 클릭" = "click_parlor_detail_tab_info",

  "장례식장 상세 - CTA" = "click_parlor_detail_CTA",
  "장례식장 상세 - 왜비싸" = "click_parlor_detail_why_expensive",
  "장례식장 상세 - 전체 후기 확인하기 클릭" = "click_parlor_detail_all_review",

  "장지 상세 - CTA" = "click_facility_detail_CTA",
  "장지 상세 - 탭클릭" = "click_facility_tab",

  "로그인 모달 - 카카오 로그인 클릭" = "click_login_modal_kakao_login",
  "로그인 모달 - 상단 닫기 클릭" = "click_login_modal_close_top",
}

export enum PixelEventName {
  "InitiateCheckout" = "InitiateCheckout", // 계약서 화면
  "ViewContent" = "ViewContent", // CTA 클릭
  "CompleteRegistration" = "CompleteRegistration", // 본인인증
  "Purchase" = "Purchase", // 결제
}

export enum DableEventName {
  "Lead" = "Lead",
  "CompleteRegistration" = "CompleteRegistration",
}

export enum NaverEventName {
  "Lead" = "3",
  "CompleteRegistration" = "4",
}
